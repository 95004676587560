.Browser .CustomSelect,
.Tablet .CustomSelect,
.Mobile .CustomSelect {
    position: relative;
}

.Browser .CustomSelect .SelectButton,
.Tablet .CustomSelect .SelectButton,
.Mobile .CustomSelect .SelectButton {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: var(--white);
    border: 2px solid var(--012-black);
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.Browser .CustomSelect .OptionSelected,
.Tablet .CustomSelect .OptionSelected,
.Mobile .CustomSelect .OptionSelected {
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    font-family: Inter, sans-serif;
    font-weight: 400;
    white-space: nowrap; /* Отключаем перенос текста */
    overflow: hidden; /* Обрезаем текст, если он не помещается */
    text-overflow: ellipsis; /* Добавляем троеточие, если текст слишком длинный */
    max-width: 80%; /* Устанавливаем максимальную ширину для текста внутри кнопки */
    color: var(--090-black);
    transition: none; /* Убираем любые переходы, если они есть */
}

.Browser .CustomSelect .DefaultSelected,
.Tablet .CustomSelect .DefaultSelected,
.Mobile .CustomSelect .DefaultSelected {
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: var(--050-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%; /* Стабильная ширина */
    transition: none; /* Убираем любые переходы, если они есть */
}

.Browser .CustomSelect .Dropdown {
    position: absolute;
    z-index: 99;
    top: 48px;
    left: 0;
    right: 0;
    min-width: 100%;
    max-width: 240px;
    white-space: nowrap;
    background-color: var(--white);;
    border: 2px solid var(--012-black);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.DropdownRelative {
    white-space: nowrap;
    background-color: var(--white);;
    border: 2px solid var(--012-black);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.DropdownRelative:hover {
    background-color: var(--004-black);
    color: var(--090-black);
}

.DropdownRelative .Option {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 15px 10px 15px;
    min-height: 44px;
    cursor: pointer;
    white-space: break-spaces;
    line-height: 18px;
    font-size: 14px;
    color: var(--050-black);
    font-weight: 400;
    font-family: Inter, sans-serif;
}

.Browser .CustomSelect .Option {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 15px 10px 15px;
    min-height: 44px;
    cursor: pointer;
    white-space: break-spaces;
    line-height: 18px;
    font-size: 14px;
    color: var(--050-black);
    font-weight: 400;
    font-family: Inter, sans-serif;
}

.Browser .CustomSelect .Option:hover {
    background-color: var(--004-black);
    color: var(--090-black);
}