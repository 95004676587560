.AcskCertificates {
}

.AcskCertificates .Browser,
.AcskCertificates .Tablet,
.AcskCertificates .Mobile {
}

.AcskCertificates .Browser .Loading,
.AcskCertificates .Tablet .Loading,
.AcskCertificates .Mobile .Loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.AcskCertificates .Browser .Download,
.AcskCertificates .Tablet .Download,
.AcskCertificates .Mobile .Download {
    display: inline-block;
}

.AcskCertificates .Browser .Download .Container,
.AcskCertificates .Tablet .Download .Container,
.AcskCertificates .Mobile .Download .Container {
    display: flex;
    flex-direction: row;
    margin: 30px 0 0;
    text-decoration: inherit;
    color: inherit;
}

.AcskCertificates .Browser .Download .Container:hover,
.AcskCertificates .Tablet .Download .Container:hover,
.AcskCertificates .Mobile .Download .Container:hover {
    text-decoration: underline;
}

.AcskCertificates .Browser .Download .Container .Left,
.AcskCertificates .Tablet .Download .Container .Left,
.AcskCertificates .Mobile .Download .Container .Left {
    display: inline-block;
    align-self: center;
    margin: 0 15px 0 0;
}

.AcskCertificates .Browser .Download .Container .Left .Icon,
.AcskCertificates .Tablet .Download .Container .Left .Icon,
.AcskCertificates .Mobile .Download .Container .Left .Icon {
    min-height: 24px;
    min-width: 24px;
}

.AcskCertificates .Browser .Download .Container .Left .Icon path,
.AcskCertificates .Tablet .Download .Container .Left .Icon path,
.AcskCertificates .Mobile .Download .Container .Left .Icon path {
    fill: var(--070-black);
}

.AcskCertificates .Browser .Download .Container .Right,
.AcskCertificates .Tablet .Download .Container .Right,
.AcskCertificates .Mobile .Download .Container .Right {
    display: inline-block;
    align-self: center;
}

.AcskCertificates .Browser .Download .Container .Right .Text,
.AcskCertificates .Tablet .Download .Container .Right .Text,
.AcskCertificates .Mobile .Download .Container .Right .Text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body1-bold-font-weight);
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
    text-decoration: inherit;
    color: var(--090-black);
}

.AcskCertificates .Browser .Row,
.AcskCertificates .Tablet .Row,
.AcskCertificates .Mobile .Row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
