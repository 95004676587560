.Main {
    flex: 1 0 auto;
}

.Main .Browser,
.Main .Tablet,
.Main .Mobile {
}

.Main .Browser .Info,
.Main .Tablet .Info,
.Main .Mobile .Info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.Main .Browser .Info .Logos,
.Main .Tablet .Info .Logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 120px 5vw 30px;
}

.Main .Mobile .Info .Logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 90px 5vw 30px;
}

.Main .Browser .Info .Logos .Logo,
.Main .Tablet .Info .Logos .Logo,
.Main .Mobile .Info .Logos .Logo {
    padding: 0 10px;
}

.Main .Browser .Info .Title,
.Main .Tablet .Info .Title,
.Main .Mobile .Info .Title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 30px;
    text-align: center;
}

.Main .Browser .Info .Target,
.Main .Tablet .Info .Target {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 120px;
    text-align: center;
}

.Main .Mobile .Info .Target {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 90px;
    text-align: center;
}

.Main .Browser .Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 60px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Tablet .Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Mobile .Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Browser .Row,
.Main .Tablet .Row,
.Main .Mobile .Row {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Browser .Applications {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 60px 0;
    margin-left: auto;
    margin-right: auto;
}

.Main .Tablet .Applications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Mobile .Applications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Browser .Applications .Title {
    width: 340px;
    padding: 0 0 0 5vw;
    margin-left: auto;
    margin-right: 10px;
}

.Main .Browser .Applications .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    padding: 0 5vw 0 0;
    margin-left: 10px;
    margin-right: auto;
}

.Main .Browser .GeneralInformation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 60px 0;
    margin-left: auto;
    margin-right: auto;
}

.Main .Tablet .GeneralInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Mobile .GeneralInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Browser .GeneralInformation .Title {
    width: 340px;
    padding: 0 0 0 5vw;
    margin-left: auto;
    margin-right: 10px;
}

.Main .Browser .GeneralInformation .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    padding: 0 5vw 0 0;
    margin-left: 10px;
    margin-right: auto;
}

.Main .Browser .Rates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1000px;
    padding: 60px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Tablet .Rates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Mobile .Rates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Main .Browser .Rates .Title {
    width: 340px;
    margin-left: auto;
    margin-right: 10px;
}

.Main .Browser .Rates .Table {
    width: 640px;
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    margin-left: 10px;
    margin-right: auto;
}

.Main .Tablet .Rates .Table,
.Main .Mobile .Rates .Table {
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
}

.Main .Browser .Rates .Table .Row,
.Main .Tablet .Rates .Table .Row,
.Main .Mobile .Rates .Table .Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.Main .Browser .Rates .Table .Row .Element,
.Main .Tablet .Rates .Table .Row .Element,
.Main .Mobile .Rates .Table .Row .Element {
    width: 40vw;
    padding: 0 10px;
}

.Main .Browser .OnlineServices,
.Main .Tablet .OnlineServices {
    padding: 0 0 75px;
}

.Main .Mobile .OnlineServices {
    padding: 0 0 60px;
}

.Main .Browser .OnlineServices .Title,
.Main .Tablet .OnlineServices .Title,
.Main .Mobile .OnlineServices .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}
