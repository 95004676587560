.TechWorks {
}

.TechWorks .Browser,
.TechWorks .Tablet,
.TechWorks .Mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
