:root {
    --white: rgba(255, 255, 255, 1) !important;
    --050-white: rgba(255, 255, 255, 0.5) !important;
    --black: rgba(0, 0, 0, 1) !important;
    --090-black: rgba(0, 0, 0, 0.9) !important;
    --070-black: rgba(0, 0, 0, 0.7) !important;
    --050-black: rgba(0, 0, 0, 0.5) !important;
    --030-black: rgba(0, 0, 0, 0.3) !important;
    --012-black: rgba(0, 0, 0, 0.12) !important;
    --008-black: rgba(0, 0, 0, 0.08) !important;
    --004-black: rgba(0, 0, 0, 0.04) !important;
    --accent: rgba(117, 175, 38, 1) !important;
    --purple: rgba(189, 181, 207, 1) !important;
    --blue: rgba(47, 128, 237, 1) !important;
    --display1-font-size: 56px !important;
    --display1-line-height: 60px !important;
    --display2-font-size: 48px !important;
    --display2-line-height: 52px !important;
    --display3-font-size: 40px !important;
    --display3-line-height: 44px !important;
    --headline1-font-size: 30px !important;
    --headline1-line-height: 34px !important;
    --headline2-font-weight: 700 !important;
    --headline2-font-size: 28px !important;
    --headline2-line-height: 28px !important;
    --headline3-font-weight: 700 !important;
    --headline3-font-size: 24px !important;
    --headline3-line-height: 28px !important;
    --subheader1-font-size: 24px !important;
    --subheader1-line-height: 28px !important;
    --subheader2-font-size: 20px !important;
    --subheader2-line-height: 24px !important;
    --body1-font-size: 17px !important;
    --body1-line-height: 22px !important;
    --body1-bold-font-weight: 600 !important;
    --body1-bold-font-size: 17px !important;
    --body1-bold-line-height: 22px !important;
    --body2-font-size: 14px !important;
    --body2-line-height: 18px !important;
    --body2-bold-font-weight: 600 !important;
    --body2-bold-font-size: 14px !important;
    --body2-bold-line-height: 20px !important;
    --body2-bold-letter-spacing: 0.02em !important;
    --caption-font-size: 12px !important;
    --caption-line-height: 16px !important;
    --caption-bold-font-weight: 600 !important;
    --caption-bold-font-size: 12px !important;
    --caption-bold-line-height: 16px !important;
    --caption-bold-letter-spacing: 0.02em !important;
    --site-title-font-weight: 800 !important;
    --site-title-font-size: 14px !important;
    --site-title-line-height: 20px !important;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: Geometria, Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    margin: 0;
    font-family: Geometria, Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}
