.Documents {
    flex: 1 0 auto;
}

.Documents .Browser,
.Documents .Tablet,
.Documents .Mobile {
}

.Documents .Browser .TopRow,
.Documents .Tablet .TopRow,
.Documents .Mobile .TopRow {
    padding: 0 12px;
}

.Documents .Browser .Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.Documents .Browser .Wrapper .Title {
    width: 340px;
    padding: 0 0 0 5vw;
    margin-left: auto;
    margin-right: 10px;
}

.Documents .Tablet .Title,
.Documents .Mobile .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Documents .Browser .Wrapper .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    padding: 0 5vw 0 0;
    margin-left: 10px;
    margin-right: auto;
}

.Documents .Browser .Wrapper .Table .Images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 60px 0 35px;
}

.Documents .Tablet .Images,
.Documents .Mobile .Images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1000px;
    padding: 0 5vw 35px;
    margin-left: auto;
    margin-right: auto;
}

.Documents .Browser .Wrapper .Table .Images .Image,
.Documents .Tablet .Images .Image,
.Documents .Mobile .Images .Image {
    height: 200px;
    width: 150px;
    margin: 0 10px 10px 0;
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}

.Documents .Browser .Wrapper .Table .Links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 120px;
}

.Documents .Tablet .Links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    max-width: 1000px;
    padding: 0 5vw 120px;
    margin-left: auto;
    margin-right: auto;
}

.Documents .Mobile .Links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    max-width: 1000px;
    padding: 0 5vw 90px;
    margin-left: auto;
    margin-right: auto;
}
