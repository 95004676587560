.DocList .Td {
    border-top: none;
    padding: 0;
    margin: 0;
}

.DocList .Td ul {
    padding: 0 20px 15px 40px;
    margin: 0;
    font-size: var(--body2-font-size);
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: var(--body2-line-height);
}

.DocList .Td .ExpandText {
    display: flex;
    cursor: pointer;
    padding: 0 20px 20px 20px;
    line-height: 24px;
    font-size: 14px;
    gap: 4px;
    height: 24px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    color: rgba(117, 175, 38, 1);
    text-align: center;
}

.Icon {
    height: 36px;
    width: 36px;
}