.Dropdown {
}

.Dropdown .Browser,
.Dropdown .Tablet,
.Dropdown .Mobile {
}

.Dropdown .Browser .Select,
.Dropdown .Tablet .Select,
.Dropdown .Mobile .Select {
    width: 40px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body2-bold-font-weight);
    font-size: var(--body2-bold-font-size);
    line-height: var(--body2-bold-line-height);
    letter-spacing: var(--body2-bold-letter-spacing);
    color: var(--090-black);
    background: transparent url("data:image/svg+xml;utf8,<svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 0L5 5L10 0H0Z' fill='black' fill-opacity='0.7'/></svg>") no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    border: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.Dropdown .Browser .Select .Option,
.Dropdown .Tablet .Select .Option,
.Dropdown .Mobile .Select .Option {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body2-bold-font-weight);
    font-size: var(--body2-bold-font-size);
    line-height: var(--body2-bold-line-height);
    letter-spacing: var(--body2-bold-letter-spacing);
    color: var(--090-black);
    background: var(--white);
}
