.Footer {
    flex-shrink: 0;
}

.Footer .Browser,
.Footer .Tablet {
    width: 100%;
    background: var(--090-black);
    text-align: center;
}

.Footer .Mobile {
    width: 100%;
    background: var(--090-black);
}

.Footer .Browser .AcskInfo,
.Footer .Tablet .AcskInfo,
.Footer .Mobile .AcskInfo {
    max-width: 1000px;
    padding: 30px 5vw 0 5vw;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--white);
}

.Footer .Browser .AcskAddress,
.Footer .Tablet .AcskAddress,
.Footer .Mobile .AcskAddress {
    max-width: 1000px;
    padding: 0 5vw 30px 5vw;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--white);
}

.Footer .Browser .Phones,
.Footer .Tablet .Phones {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--subheader1-font-size);
    line-height: var(--subheader1-line-height);
    color: var(--white);
}

.Footer .Mobile .Phones {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 90vw;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Footer .Browser .Phones .Link,
.Footer .Tablet .Phones .Link,
.Footer .Mobile .Phones .Link {
    text-decoration: inherit;
    color: inherit;
}

.Footer .Browser .PrivatBank,
.Footer .Tablet .PrivatBank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    width: 90vw;
    padding: 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Footer .Mobile .PrivatBank {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1000px;
    width: 90vw;
    padding: 30px 5vw;
}

.Footer .Browser .PrivatBank .PrivatBankInfo,
.Footer .Tablet .PrivatBank .PrivatBankInfo {
    width: 140px;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-font-size);
    line-height: var(--caption-line-height);
    color: var(--white);
}

.Footer .Mobile .PrivatBank .PrivatBankInfo {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-font-size);
    line-height: var(--caption-line-height);
    color: var(--white);
}

.Footer .Browser .PrivatBank .Separator,
.Footer .Tablet .PrivatBank .Separator,
.Footer .Mobile .PrivatBank .Separator {
    margin: 0 6px;
    color: var(--white);
}

.Footer .Browser .PrivatBank .PrivatBankLink,
.Footer .Tablet .PrivatBank .PrivatBankLink {
    width: 140px;
    display: flex;
    justify-content: flex-start;
    text-underline-offset: 2px;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-font-size);
    line-height: var(--caption-line-height);
    color: var(--white);
}

.Footer .Mobile .PrivatBank .PrivatBankLink {
    text-underline-offset: 2px;
    text-decoration-skip-ink: none;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-font-size);
    line-height: var(--caption-line-height);
    color: var(--white);
}