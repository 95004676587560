.Certificates {
    flex: 1 0 auto;
}

.Certificates .Browser,
.Certificates .Tablet,
.Certificates .Mobile {
}

.Certificates .Browser .TopRow,
.Certificates .Tablet .TopRow,
.Certificates .Mobile .TopRow {
    padding: 0 12px;
}

.Certificates .Browser .Title,
.Certificates .Tablet .Title,
.Certificates .Mobile .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Certificates .Browser .Content,
.Certificates .Tablet .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 120px;
    margin-left: auto;
    margin-right: auto;
}

.Certificates .Mobile .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 90px;
    margin-left: auto;
    margin-right: auto;
}

.Certificates .Browser .Content .MenuContainer,
.Certificates .Tablet .Content .MenuContainer,
.Certificates .Mobile .Content .MenuContainer {
}

.Certificates .Browser .Content .MenuContainer .Menu,
.Certificates .Tablet .Content .MenuContainer .Menu,
.Certificates .Mobile .Content .MenuContainer .Menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
}

.Certificates .Browser .Content .MenuContainer .Menu .Tab,
.Certificates .Tablet .Content .MenuContainer .Menu .Tab,
.Certificates .Mobile .Content .MenuContainer .Menu .Tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
}

.Certificates .Browser .Content .MenuContainer .Menu .Tab:not(:last-child),
.Certificates .Tablet .Content .MenuContainer .Menu .Tab:not(:last-child),
.Certificates .Mobile .Content .MenuContainer .Menu .Tab:not(:last-child) {
    padding: 0 30px 0 0;
}

.Certificates .Browser .Content .MenuContainer .Menu .Tab .Selected,
.Certificates .Tablet .Content .MenuContainer .Menu .Tab .Selected,
.Certificates .Mobile .Content .MenuContainer .Menu .Tab .Selected {
    margin: 0 0 15px;
    text-align: center;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline3-font-weight);
    font-size: var(--headline3-font-size);
    line-height: var(--headline3-line-height);
    text-decoration: inherit;
    color: var(--090-black);
}

.Certificates .Browser .Content .MenuContainer .Menu .Tab .Unselected,
.Certificates .Tablet .Content .MenuContainer .Menu .Tab .Unselected,
.Certificates .Mobile .Content .MenuContainer .Menu .Tab .Unselected {
    margin: 0 0 15px;
    text-align: center;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline3-font-weight);
    font-size: var(--headline3-font-size);
    line-height: var(--headline3-line-height);
    text-decoration: inherit;
    color: var(--030-black);
}

.Certificates .Browser .Content .MenuContainer .Menu .Tab .Line,
.Certificates .Tablet .Content .MenuContainer .Menu .Tab .Line,
.Certificates .Mobile .Content .MenuContainer .Menu .Tab .Line {
    height: 3px;
    width: 100%;
    margin: 0;
    border: 0;
    background: var(--accent);
}

.Certificates .Browser .Content .BottomRow,
.Certificates .Tablet .Content .BottomRow,
.Certificates .Mobile .Content .BottomRow {
}
