.Headline {
}

.Headline .Browser,
.Headline .Tablet {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline2-font-weight);
    font-size: var(--headline2-font-size);
    line-height: var(--headline2-line-height);
    color: var(--090-black);
}

.Headline .Mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline3-font-weight);
    font-size: var(--headline3-font-size);
    line-height: var(--headline3-line-height);
    color: var(--090-black);
}
