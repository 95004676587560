.SmartID {
    flex: 1 0 auto;
}

.SmartID .Browser,
.SmartID .Tablet,
.SmartID .Mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SmartID .Browser .Link,
.SmartID .Tablet .Link,
.SmartID .Mobile .Link {
    text-decoration: inherit;
    color: var(--blue);
}

.SmartID .Mobile .ContactMobile {
    margin: 0 5vw 35px;
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: flex-start;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline3-font-weight);
    font-size: var(--subheader2-font-size);
    line-height: var(--subheader2-line-height);
    color: var(--090-black);
}

.SmartID .Browser .Info,
.SmartID .Tablet .Info,
.SmartID .Mobile .Info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.SmartID .Browser .Info .Logo,
.SmartID .Tablet .Info .Logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max(30vw, 20vh);
    padding: 120px 5vw 30px;
    z-index: 10;
}

.SmartID .Mobile .Info .Logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max(30vw, 20vh);
    padding: 90px 5vw 30px;
    z-index: 10;
}

.SmartID .Browser .Info .Text,
.SmartID .Tablet .Info .Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 120px;
    text-align: center;
}

.SmartID .Mobile .Info .Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 90px;
    text-align: center;
}

.SmartID .Browser .Content,
.SmartID .Tablet .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    max-width: 1000px;
    margin: 0 5vw 50px;
}

.SmartID .Mobile .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    max-width: 1000px;
    margin: 0 5vw 35px;
}
