.FrequentlyAskedQuestions {
    flex: 1 0 auto;
}

.FrequentlyAskedQuestions .Browser,
.FrequentlyAskedQuestions .Tablet,
.FrequentlyAskedQuestions .Mobile {
}

.FrequentlyAskedQuestions .Browser .TopRow,
.FrequentlyAskedQuestions .Tablet .TopRow,
.FrequentlyAskedQuestions .Mobile .TopRow {
    padding: 0 12px;
}

.FrequentlyAskedQuestions .Browser .Title,
.FrequentlyAskedQuestions .Tablet .Title,
.FrequentlyAskedQuestions .Mobile .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.FrequentlyAskedQuestions .Browser .Content,
.FrequentlyAskedQuestions .Tablet .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 120px;
    margin-left: auto;
    margin-right: auto;
}

.FrequentlyAskedQuestions .Mobile .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 90px;
    margin-left: auto;
    margin-right: auto;
}
