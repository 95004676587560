.ClientsCertificates {
}

.ClientsCertificates .Browser,
.ClientsCertificates .Tablet,
.ClientsCertificates .Mobile {
}

.ClientsCertificates .Browser .SearchContainer,
.ClientsCertificates .Tablet .SearchContainer,
.ClientsCertificates .Mobile .SearchContainer {
}

.ClientsCertificates .Browser .SearchContainer .Inputs,
.ClientsCertificates .Tablet .SearchContainer .Inputs,
.ClientsCertificates .Mobile .SearchContainer .Inputs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ClientsCertificates .Browser .SearchContainer .Inputs .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 24%;
    padding: 30px 0 0;
}

.ClientsCertificates .Tablet .SearchContainer .Inputs .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 49%;
    padding: 30px 0 0;
}

.ClientsCertificates .Mobile .SearchContainer .Inputs .InputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 30px 0 0;
}

.ClientsCertificates .Browser .SearchContainer .Inputs .InputContainer:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 49%;
    padding: 30px 0 0;
}

.ClientsCertificates .Tablet .SearchContainer .Inputs .InputContainer:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 30px 0 0;
}

.ClientsCertificates .Browser .SearchContainer .Inputs .InputContainer .Icon,
.ClientsCertificates .Tablet .SearchContainer .Inputs .InputContainer .Icon,
.ClientsCertificates .Mobile .SearchContainer .Inputs .InputContainer .Icon {
    min-height: 24px;
    min-width: 24px;
}

.ClientsCertificates .Browser .SearchContainer .Inputs .InputContainer .Input,
.ClientsCertificates .Tablet .SearchContainer .Inputs .InputContainer .Input,
.ClientsCertificates .Mobile .SearchContainer .Inputs .InputContainer .Input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 90vw;
    padding: 9px 15px;
    margin-top: 5px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--090-black);
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 6px;
    caret-color: var(--090-black);
    outline: none;
    animation: shake;
    animation-iteration-count: infinite;
    animation-play-state: running;
    transform-origin: center center;
}

.ClientsCertificates .Browser .SearchContainer .Inputs .InputContainer .Input::placeholder,
.ClientsCertificates .Tablet .SearchContainer .Inputs .InputContainer .Input::placeholder,
.ClientsCertificates .Mobile .SearchContainer .Inputs .InputContainer .Input::placeholder {
    color: var(--050-black);
}

.ClientsCertificates .Browser .SearchContainer .CheckBoxContainer,
.ClientsCertificates .Tablet .SearchContainer .CheckBoxContainer,
.ClientsCertificates .Mobile .SearchContainer .CheckBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 30px 0 0;
}

.ClientsCertificates .Browser .SearchContainer .Row,
.ClientsCertificates .Tablet .SearchContainer .Row,
.ClientsCertificates .Mobile .SearchContainer .Row {
    padding: 30px 0 0;
}

.ClientsCertificates .Browser .SearchContainer .Recaptcha,
.ClientsCertificates .Tablet .SearchContainer .Recaptcha,
.ClientsCertificates .Mobile .SearchContainer .Recaptcha {
    padding: 30px 0 0;
}

.ClientsCertificates .Browser .SearchContainer .Search,
.ClientsCertificates .Tablet .SearchContainer .Search {
    width: 90vw;
    max-width: 330px;
    padding: 30px 0 0;
}

.ClientsCertificates .Mobile .SearchContainer .Search {
    width: 90vw;
    max-width: 1000px;
    padding: 30px 0 0;
}

.ClientsCertificates .Browser .SearchContainer .Search .Button,
.ClientsCertificates .Tablet .SearchContainer .Search .Button,
.ClientsCertificates .Mobile .SearchContainer .Search .Button {
    width: 100%;
    padding: 11px 22px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body1-bold-font-weight);
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
    color: var(--050-black);
    background: var(--white);
    border: 2px solid var(--090-black);
    border-radius: 8px;
    transition: 0.5s;
    outline: none;
    cursor: pointer;
}

.ClientsCertificates .Browser .SearchContainer .Search .Button:hover,
.ClientsCertificates .Tablet .SearchContainer .Search .Button:hover,
.ClientsCertificates .Mobile .SearchContainer .Search .Button:hover {
    color: var(--090-black);
}

.ClientsCertificates .Browser .SearchContainer .Search .Button:disabled,
.ClientsCertificates .Tablet .SearchContainer .Search .Button:disabled,
.ClientsCertificates .Mobile .SearchContainer .Search .Button:disabled {
    width: 100%;
    padding: 11px 22px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body1-bold-font-weight);
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
    color: var(--white);
    background: var(--030-black);
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    cursor: default;
}

.ClientsCertificates .Browser .ContentContainer,
.ClientsCertificates .Tablet .ContentContainer,
.ClientsCertificates .Mobile .ContentContainer {
}

.ClientsCertificates .Browser .ContentContainer .Back,
.ClientsCertificates .Tablet .ContentContainer .Back {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin: 30px 0 0;
    cursor: pointer;
}

.ClientsCertificates .Mobile .ContentContainer .Back {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin: 25px 0 0;
    cursor: pointer;
}

.ClientsCertificates .Browser .ContentContainer .Back .Icon,
.ClientsCertificates .Tablet .ContentContainer .Back .Icon,
.ClientsCertificates .Mobile .ContentContainer .Back .Icon {
    min-height: 24px;
    min-width: 24px;
}

.ClientsCertificates .Browser .ContentContainer .SearchInfoContainer,
.ClientsCertificates .Tablet .ContentContainer .SearchInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px 0 0;
}

.ClientsCertificates .Mobile .ContentContainer .SearchInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 0 0;
}

.ClientsCertificates .Browser .ContentContainer .SearchInfoContainer .SearchInfo,
.ClientsCertificates .Tablet .ContentContainer .SearchInfoContainer .SearchInfo,
.ClientsCertificates .Mobile .ContentContainer .SearchInfoContainer .SearchInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 16px 10px 12px;
    margin: 10px 10px 0 0;
    word-break: break-all;
    background: var(--004-black);
    border-radius: 40px;
}

.ClientsCertificates .Browser .ContentContainer .SearchInfoContainer .SearchInfo .Icon,
.ClientsCertificates .Tablet .ContentContainer .SearchInfoContainer .SearchInfo .Icon,
.ClientsCertificates .Mobile .ContentContainer .SearchInfoContainer .SearchInfo .Icon {
    display: flex;
    min-height: 24px;
    min-width: 24px;
    margin-right: 10px;
}

.ClientsCertificates .Browser .ContentContainer .MultiCheckBox,
.ClientsCertificates .Tablet .ContentContainer .MultiCheckBox,
.ClientsCertificates .Mobile .ContentContainer .MultiCheckBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.ClientsCertificates .Browser .ContentContainer .MultiCheckBox .Text,
.ClientsCertificates .Tablet .ContentContainer .MultiCheckBox .Text,
.ClientsCertificates .Mobile .ContentContainer .MultiCheckBox .Text {
    margin: 0 15px;
}

.ClientsCertificates .Browser .ContentContainer .Content,
.ClientsCertificates .Tablet .ContentContainer .Content,
.ClientsCertificates .Mobile .ContentContainer .Content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate {
    max-width: 1000px;
    margin: 30px 0 0;
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    border-spacing: 0;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate {
    width: 485px;
    max-width: 90vw;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate th, td,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate th, td {
    position: sticky;
    position: -webkit-sticky;
    text-align: left;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate th:first-child, td:first-child,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate th:first-child, td:first-child {
    padding: 15px 20px;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate th:not(:first-child), td:not(:first-child),
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate th:not(:first-child), td:not(:first-child) {
    padding: 15px 20px 15px 0;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate td,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate td {
    border-top: 2px solid var(--012-black);
    word-break: break-word;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate td:nth-child(2),
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate td:nth-child(2) {
    word-break: keep-all;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate td:nth-child(4),
.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate td:nth-child(6),
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate td:nth-child(4),
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate td:nth-child(6) {
    white-space: nowrap;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate .MultiCheckBoxContainer,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate .MultiCheckBoxContainer {
    display: flex;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0;
    margin: 30px 0 0;
    border: 2px solid var(--012-black);
    border-radius: 8px;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card .Row {
    display: flex;
    flex-direction: row;
    padding: 0 0 15px;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card .Row .Left {
    display: inline-block;
    align-self: center;
    width: 80px;
    min-width: 80px;
    margin: 0 15px 0 0;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card .Row .Right {
    display: inline-block;
    align-self: center;
    word-break: break-word;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate .Flex,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate .Flex,
.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card .Row .Right .Flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card .Row .Right .Inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ClientsCertificates .Browser .ContentContainer .Content .ClientsCertificate .Icon,
.ClientsCertificates .Tablet .ContentContainer .Content .ClientsCertificate .Icon {
    min-height: 36px;
    min-width: 36px;
}

.ClientsCertificates .Mobile .ContentContainer .Content .ClientsCertificate .Card .Row .Right .Inline .Icon {
    min-height: 36px;
    min-width: 36px;
    margin-left: -6px;
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel {
    width: 100%;
    position: fixed;
    bottom: -1px;
    left: 0;
    background-color: var(--white);
    box-shadow: 0 0 16px var(--008-black);
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container {
    max-width: 1000px;
    padding: 20px 5vw 30px;
    margin-left: auto;
    margin-right: auto;
}

@media {
    .ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .Shake,
    .ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .Shake,
    .ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .Shake {
        width: fit-content;
        padding: 0 0 15px;
        animation: shake;
        animation-iteration-count: infinite;
        animation-play-state: running;
        transform-origin: center center;
    }
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .Shake .Checked,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .Shake .Checked,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .Shake .Checked {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .Shake .CheckedMax,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .Shake .CheckedMax,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .Shake .CheckedMax {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-font-size);
    line-height: var(--caption-line-height);
    color: var(--050-black);
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .ActionsMenu,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .ActionsMenu,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .ActionsMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .ActionsMenu .Action,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .ActionsMenu .Action,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .ActionsMenu .Action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 16px 10px 12px;
    white-space: nowrap;
    background-color: var(--004-black);
    border-radius: 40px;
    user-select: none;
    cursor: pointer;
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .ActionsMenu .Action:not(:last-child),
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .ActionsMenu .Action:not(:last-child),
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .ActionsMenu .Action:not(:last-child) {
    margin: 0 10px 0 0;
}

.ClientsCertificates .Browser .ContentContainer .ActionPanel .Container .ActionsMenu .Action .Icon,
.ClientsCertificates .Tablet .ContentContainer .ActionPanel .Container .ActionsMenu .Action .Icon,
.ClientsCertificates .Mobile .ContentContainer .ActionPanel .Container .ActionsMenu .Action .Icon {
    display: flex;
    min-height: 24px;
    min-width: 24px;
    margin-right: 10px;
}

.ClientsCertificates .Browser .Processing,
.ClientsCertificates .Tablet .Processing,
.ClientsCertificates .Mobile .Processing {
}

.ClientsCertificates .Browser .Processing .Blur,
.ClientsCertificates .Tablet .Processing .Blur,
.ClientsCertificates .Mobile .Processing .Blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(7px);
    z-index: 1000;
}

.ClientsCertificates .Browser .Processing .Blur .Animation,
.ClientsCertificates .Tablet .Processing .Blur .Animation,
.ClientsCertificates .Mobile .Processing .Blur .Animation {
    min-height: 200px;
    min-width: 200px;
}

@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    10% {
        transform: translate(1.9664300981627578px, 1.1485106701459067px) rotate(-0.312808073345457deg);
    }
    20% {
        transform: translate(-0.9334525100145585px, 1.0743489919170468px) rotate(1.3661649501334425deg);
    }
    30% {
        transform: translate(-1.2032055809632136px, 0.8727384227540638px) rotate(-1.0274227467364851deg);
    }
    40% {
        transform: translate(0.6361825122293219px, -0.0008380617845782368px) rotate(-0.7380664520134306deg);
    }
    50% {
        transform: translate(-1.9209610593295434px, -0.7182490648773721px) rotate(0.8911730795054922deg);
    }
    60% {
        transform: translate(1.4440824810079542px, 0.6822685316249322px) rotate(-0.9084844531425011deg);
    }
    70% {
        transform: translate(2.459522144583892px, -1.0209342739958571px) rotate(1.0069228611890857deg);
    }
    80% {
        transform: translate(0.13877099682136684px, -0.604216931622269px) rotate(-0.4202718518246751deg);
    }
    90% {
        transform: translate(2.4079118819966556px, 1.3386627377049254px) rotate(-1.1058704428553776deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}
