.Row {
}

.Row .Browser,
.Row .Tablet,
.Row .Mobile {
}

.Row .Browser .Horizontal,
.Row .Tablet .Horizontal,
.Row .Mobile .Horizontal {
}

.Row .Browser .Vertical,
.Row .Tablet .Vertical,
.Row .Mobile .Vertical {
    display: flex;
    flex-direction: column;
}

.Row .Browser .Horizontal .Line,
.Row .Tablet .Horizontal .Line,
.Row .Mobile .Horizontal .Line {
    display: inline;
    margin: 0 30px;
    color: var(--012-black);
}

.Row .Browser .Vertical .Line,
.Row .Tablet .Vertical .Line,
.Row .Mobile .Vertical .Line {
    width: 100%;
    height: 2px;
    margin: 0;
    border: 0;
    background: var(--012-black);
}

.Row .Browser .Horizontal .Content,
.Row .Tablet .Horizontal .Content,
.Row .Mobile .Horizontal .Content {
    display: inline;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--090-black);
}

.Row .Browser .Vertical .Link,
.Row .Tablet .Vertical .Link,
.Row .Mobile .Vertical .Link {
    text-decoration: inherit;
    color: inherit;
}

.Row .Browser .Vertical .Content,
.Row .Tablet .Vertical .Content,
.Row .Mobile .Vertical .Content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--090-black);
}

.Row .Browser .Vertical .ContentCollapsed,
.Row .Tablet .Vertical .ContentCollapsed,
.Row .Mobile .Vertical .ContentCollapsed {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 30px 0 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--090-black);
    cursor: pointer;
}

.Row .Browser .Vertical .ContentExpanded,
.Row .Tablet .Vertical .ContentExpanded,
.Row .Mobile .Vertical .ContentExpanded {
    margin: 30px 0 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--090-black);
}

.Row .Browser .Vertical .Content .ListIcon,
.Row .Tablet .Vertical .Content .ListIcon,
.Row .Mobile .Vertical .Content .ListIcon {
    display: flex;
    min-height: 22px;
    min-width: 22px;
    padding: 0 10px 0 0;
}

.Row .Browser .Horizontal .Content .Link,
.Row .Tablet .Horizontal .Content .Link,
.Row .Mobile .Horizontal .Content .Link {
    text-decoration: inherit;
    color: inherit;
}

.Row .Browser .Vertical .Content .Text {
    width: 95%;
}

.Row .Tablet .Vertical .Content .Text {
    width: 90%;
}

.Row .Mobile .Vertical .Content .Text {
    width: 85%;
}

.Row .Browser .Vertical .Content .WhiteIcon,
.Row .Tablet .Vertical .Content .WhiteIcon,
.Row .Mobile .Vertical .Content .WhiteIcon,
.Row .Browser .Vertical .Content .DarkIcon,
.Row .Tablet .Vertical .Content .DarkIcon,
.Row .Mobile .Vertical .Content .DarkIcon {
    display: flex;
    min-height: 24px;
    min-width: 24px;
}

.Row .Browser .Vertical .Content .WhiteIcon path,
.Row .Tablet .Vertical .Content .WhiteIcon path,
.Row .Mobile .Vertical .Content .WhiteIcon path {
    fill: var(--white);
}

.Row .Browser .Vertical .Content .DarkIcon path,
.Row .Tablet .Vertical .Content .DarkIcon path,
.Row .Mobile .Vertical .Content .DarkIcon path {
    fill: var(--070-black);
}

.Row .Browser .Vertical .ContentCollapsed .HeadlineText {
    width: 95%;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline2-font-weight);
    font-size: var(--headline2-font-size);
    line-height: var(--headline2-line-height);
    color: var(--090-black);
}

.Row .Tablet .Vertical .ContentCollapsed .HeadlineText {
    width: 90%;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline2-font-weight);
    font-size: var(--headline2-font-size);
    line-height: var(--headline2-line-height);
    color: var(--090-black);
}

.Row .Mobile .Vertical .ContentCollapsed .HeadlineText {
    width: 85%;
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: var(--headline3-font-weight);
    font-size: var(--headline3-font-size);
    line-height: var(--headline3-line-height);
    color: var(--090-black);
}

.Row .Browser .Vertical .ContentCollapsed .ExpandIcon,
.Row .Tablet .Vertical .ContentCollapsed .ExpandIcon,
.Row .Mobile .Vertical .ContentCollapsed .ExpandIcon {
    display: flex;
    min-height: 24px;
    min-width: 24px;
    padding: 0 10px 0 0;
}

.Row .Browser .Vertical .ContentCollapsed .CollapseIcon,
.Row .Tablet .Vertical .ContentCollapsed .CollapseIcon,
.Row .Mobile .Vertical .ContentCollapsed .CollapseIcon {
    display: flex;
    min-height: 24px;
    min-width: 24px;
    padding: 0 10px 0 0;
}
