.Browser,
.Tablet,
.Mobile {
}

.Tablet .FilterScroll,
.Mobile .FilterScroll {
    overflow-x: auto;
}

.Browser .FilterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 30px;
}

.Tablet .FilterContainer,
.Mobile .FilterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    margin-top: 30px;
    position: relative;
}

.Browser .FilterContainer .FilterItemDateRange {
    width: 270px;
}

.Tablet .FilterContainer .FilterItemDateRange,
.Mobile .FilterContainer .FilterItemDateRange {
    position: relative;
    flex-grow: 2;
    max-width: 300px;
    min-width: 255px;
}

.Browser .FilterContainer .FilterItem {
    width: 190px;
}

.Tablet .FilterContainer .FilterItem,
.Mobile .FilterContainer .FilterItem {
    flex-grow: 1;
    position: relative;
}