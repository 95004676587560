.LogTableHead {

}

.LogTableHead th,
.LogTableHead td {
    position: sticky;
    position: -webkit-sticky;
    text-align: left;
}

.LogTableHead .DateRow td {
    font-size: var(--caption-font-size);
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 24px;
    line-height: var(--caption-line-height);
    color: var(--050-black);
    padding: 0 20px 0 20px;
    background-color: var(--004-black);
}

.LogTableHead th:first-child,
.LogTableHead td:first-child {
    padding: 15px 20px;
    width: 70px;
}

.LogTableHead th:not(:first-child),
.LogTableHead td:not(:first-child) {
    padding: 15px 20px 15px 0;
}

.LogTableHead th:not(:first-child):not(:last-child) {
    min-width: 80px;
    max-width: 190px;
}

.LogTableHead th:last-child,
.LogTableHead td:last-child {
    width: 36px;
}

.LogTableHead tr td {
    border-top: 2px solid var(--012-black);
    word-break: break-word;
}
