.RevokedCertificates {
}

.RevokedCertificates .Browser,
.RevokedCertificates .Tablet,
.RevokedCertificates .Mobile {
}

.RevokedCertificates .Browser .Loading,
.RevokedCertificates .Tablet .Loading,
.RevokedCertificates .Mobile .Loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.RevokedCertificates .Browser .Row,
.RevokedCertificates .Tablet .Row,
.RevokedCertificates .Mobile .Row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
