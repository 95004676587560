.TableBody th,
.TableBody td {
    position: sticky;
    position: -webkit-sticky;
    text-align: left;
}

.TableBody .DateRow td {
    font-size: var(--caption-font-size);
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 24px;
    line-height: var(--caption-line-height);
    color: var(--050-black);
    padding: 0 20px 0 20px;
    background-color: var(--004-black);
}

.TableBody td:not(:first-child) {
    padding: 15px 20px 15px 0;
}

.TableBody td:not(:first-child):not(:last-child) {
    min-width: 80px;
    max-width: 190px;
}

.TableBody td:last-child {
    width: 36px;
}

.TableBody tr td {
    border-top: 2px solid var(--012-black);
    word-break: break-word;
}
