.DateRow {
    font-size: var(--caption-font-size);
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 24px;
    line-height: var(--caption-line-height);
    color: var(--050-black);
    padding: 0 20px 0 20px;
    background-color: var(--004-black);
    border-radius: 8px;
    margin-top: 30px;
    display: flex;
    align-items: center; /* Центровка по вертикали */
    justify-content: flex-start; /* Выравнивание текста слева */
}