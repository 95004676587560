.Intro {
    width: 100%;
}

.Intro .Browser,
.Intro .Tablet,
.Intro .Mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 105%;
    width: 100%;
    background: var(--purple);
}
