.NormativeBase {
    flex: 1 0 auto;
}

.NormativeBase .Browser,
.NormativeBase .Tablet,
.NormativeBase .Mobile {
}

.NormativeBase .Browser .TopRow,
.NormativeBase .Tablet .TopRow,
.NormativeBase .Mobile .TopRow {
    padding: 0 12px;
}

.NormativeBase .Browser .Title,
.NormativeBase .Tablet .Title,
.NormativeBase .Mobile .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.NormativeBase .Browser .Content,
.NormativeBase .Tablet .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 120px;
    margin-left: auto;
    margin-right: auto;
}

.NormativeBase .Mobile .Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 90px;
    margin-left: auto;
    margin-right: auto;
}
