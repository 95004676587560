.PersonalCertificates {
}

.PersonalCertificates .Browser,
.PersonalCertificates .Tablet,
.PersonalCertificates .Mobile {
}

.PersonalCertificates .Browser .Loading,
.PersonalCertificates .Tablet .Loading,
.PersonalCertificates .Mobile .Loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.PersonalCertificates .Browser .Empty,
.PersonalCertificates .Tablet .Empty,
.PersonalCertificates .Mobile .Empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.PersonalCertificates .Browser .Container,
.PersonalCertificates .Tablet .Container,
.PersonalCertificates .Mobile .Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.PersonalCertificates .Browser .Container .Search,
.PersonalCertificates .Tablet .Container .Search,
.PersonalCertificates .Mobile .Container .Search {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 1vw 10px 50px;
    margin-top: 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    caret-color: var(--090-black);
    outline: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 50 50' fill='%23000000' fill-opacity='0.7'%3e%3cpath fill='%23000000' fill-opacity='0.7' d='M21 3C11.601563 3 4 10.601563 4 20C4 29.398438 11.601563 37 21 37C24.355469 37 27.460938 36.015625 30.09375 34.34375L42.375 46.625L46.625 42.375L34.5 30.28125C36.679688 27.421875 38 23.878906 38 20C38 10.601563 30.398438 3 21 3 Z M 21 7C28.199219 7 34 12.800781 34 20C34 27.199219 28.199219 33 21 33C13.800781 33 8 27.199219 8 20C8 12.800781 13.800781 7 21 7Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: 15px;
    background-size: 20px, 20px;
}

.PersonalCertificates .Browser .Container .Search::placeholder,
.PersonalCertificates .Tablet .Container .Search::placeholder,
.PersonalCertificates .Mobile .Container .Search::placeholder {
    color: var(--050-black);
}

.PersonalCertificates .Browser .FiltersAndSorts,
.PersonalCertificates .Tablet .FiltersAndSorts,
.PersonalCertificates .Mobile .FiltersAndSorts {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.PersonalCertificates .Browser .FiltersAndSorts .Filter,
.PersonalCertificates .Tablet .FiltersAndSorts .Filter {
    width: 49%;
    max-width: 90vw;
    padding: 10px 15px 10px 44px;
    margin-top: 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z' fill='black' fill-opacity='0.7'/></svg>") no-repeat;
    background-position-x: 10px;
    background-position-y: 50%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.PersonalCertificates .Mobile .FiltersAndSorts .Filter {
    width: 100%;
    max-width: 90vw;
    padding: 10px 15px 10px 44px;
    margin-top: 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z' fill='black' fill-opacity='0.7'/></svg>") no-repeat;
    background-position-x: 10px;
    background-position-y: 50%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.PersonalCertificates .Browser .FiltersAndSorts .Sort,
.PersonalCertificates .Tablet .FiltersAndSorts .Sort {
    width: 49%;
    max-width: 90vw;
    padding: 10px 15px 10px 44px;
    margin-top: 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z' fill='black' fill-opacity='0.7'/></svg>") no-repeat;
    background-position-x: 10px;
    background-position-y: 50%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.PersonalCertificates .Mobile .FiltersAndSorts .Sort {
    width: 100%;
    max-width: 90vw;
    padding: 10px 15px 10px 44px;
    margin-top: 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z' fill='black' fill-opacity='0.7'/></svg>") no-repeat;
    background-position-x: 10px;
    background-position-y: 50%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.PersonalCertificates .Browser .FiltersAndSorts .Filter .Option,
.PersonalCertificates .Tablet .FiltersAndSorts .Filter .Option,
.PersonalCertificates .Mobile .FiltersAndSorts .Filter .Option,
.PersonalCertificates .Browser .FiltersAndSorts .Sort .Option,
.PersonalCertificates .Tablet .FiltersAndSorts .Sort .Option,
.PersonalCertificates .Mobile .FiltersAndSorts .Sort .Option {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
    background: var(--white);
}

.PersonalCertificates .Browser .MultiCheckBox,
.PersonalCertificates .Tablet .MultiCheckBox,
.PersonalCertificates .Mobile .MultiCheckBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.PersonalCertificates .Browser .MultiCheckBox .Text,
.PersonalCertificates .Tablet .MultiCheckBox .Text,
.PersonalCertificates .Mobile .MultiCheckBox .Text {
    margin: 0 15px;
}

.PersonalCertificates .Browser .Content,
.PersonalCertificates .Tablet .Content,
.PersonalCertificates .Mobile .Content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.PersonalCertificates .Browser .Content .NotFound,
.PersonalCertificates .Tablet .Content .NotFound,
.PersonalCertificates .Mobile .Content .NotFound {
    width: 100%;
    margin-top: 30px;
    text-align: center;
}

.PersonalCertificates .Browser .Content .PersonalCertificate,
.PersonalCertificates .Tablet .Content .PersonalCertificate {
    max-width: 1000px;
    margin: 30px 0 0;
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    border-spacing: 0;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate {
    width: 485px;
    max-width: 90vw;
}

.PersonalCertificates .Browser .Content .PersonalCertificate th, td,
.PersonalCertificates .Tablet .Content .PersonalCertificate th, td {
    position: sticky;
    position: -webkit-sticky;
    text-align: left;
}

.PersonalCertificates .Browser .Content .PersonalCertificate th:first-child, td:first-child,
.PersonalCertificates .Tablet .Content .PersonalCertificate th:first-child, td:first-child {
    padding: 15px 20px;
}

.PersonalCertificates .Browser .Content .PersonalCertificate th:not(:first-child), td:not(:first-child),
.PersonalCertificates .Tablet .Content .PersonalCertificate th:not(:first-child), td:not(:first-child) {
    padding: 15px 20px 15px 0;
}

.PersonalCertificates .Browser .Content .PersonalCertificate td,
.PersonalCertificates .Tablet .Content .PersonalCertificate td {
    border-top: 2px solid var(--012-black);
    word-break: break-word;
}

.PersonalCertificates .Browser .Content .PersonalCertificate td:nth-child(2),
.PersonalCertificates .Tablet .Content .PersonalCertificate td:nth-child(2) {
    word-break: keep-all;
}

.PersonalCertificates .Browser .Content .PersonalCertificate td:nth-child(4),
.PersonalCertificates .Browser .Content .PersonalCertificate td:nth-child(6),
.PersonalCertificates .Tablet .Content .PersonalCertificate td:nth-child(4),
.PersonalCertificates .Tablet .Content .PersonalCertificate td:nth-child(6) {
    white-space: nowrap;
}

.PersonalCertificates .Browser .Content .PersonalCertificate .MultiCheckBoxContainer,
.PersonalCertificates .Tablet .Content .PersonalCertificate .MultiCheckBoxContainer {
    display: flex;
}

.PersonalCertificates .Browser .Content .PersonalCertificate .IconContainer,
.PersonalCertificates .Tablet .Content .PersonalCertificate .IconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0;
    margin: 30px 0 0;
    border: 2px solid var(--012-black);
    border-radius: 8px;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row {
    display: flex;
    flex-direction: row;
    padding: 0 0 15px;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row .Left {
    display: inline-block;
    align-self: center;
    width: 80px;
    min-width: 80px;
    margin: 0 15px 0 0;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row .Right {
    display: inline-block;
    align-self: center;
    word-break: break-word;
}

.PersonalCertificates .Browser .Content .PersonalCertificate .Flex,
.PersonalCertificates .Tablet .Content .PersonalCertificate .Flex,
.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row .Right .Flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row .Right .Inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PersonalCertificates .Browser .Content .PersonalCertificate .IconContainer .Icon,
.PersonalCertificates .Tablet .Content .PersonalCertificate .IconContainer .Icon {
    min-height: 36px;
    min-width: 36px;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row .Right .Inline .Icon {
    min-height: 36px;
    min-width: 36px;
    margin-left: -6px;
}

.PersonalCertificates .Browser .Content .PersonalCertificate .IconContainer .PublicationIcon,
.PersonalCertificates .Tablet .Content .PersonalCertificate .IconContainer .PublicationIcon {
    min-height: 24px;
    min-width: 24px;
    padding: 6px;
}

.PersonalCertificates .Mobile .Content .PersonalCertificate .Card .Row .Right .Inline .PublicationIcon {
    min-height: 24px;
    min-width: 24px;
}

.PersonalCertificates .Browser .ActionPanel,
.PersonalCertificates .Tablet .ActionPanel,
.PersonalCertificates .Mobile .ActionPanel {
    width: 100%;
    position: fixed;
    bottom: -1px;
    left: 0;
    background-color: var(--white);
    box-shadow: 0 0 16px var(--008-black);
}

.PersonalCertificates .Browser .ActionPanel .Container,
.PersonalCertificates .Tablet .ActionPanel .Container,
.PersonalCertificates .Mobile .ActionPanel .Container {
    max-width: 1000px;
    padding: 20px 5vw 30px;
    margin-left: auto;
    margin-right: auto;
}

@media {
    .PersonalCertificates .Browser .ActionPanel .Container .Shake,
    .PersonalCertificates .Tablet .ActionPanel .Container .Shake,
    .PersonalCertificates .Mobile .ActionPanel .Container .Shake {
        width: fit-content;
        padding: 0 0 15px;
        animation: shake;
        animation-iteration-count: infinite;
        animation-play-state: running;
        transform-origin: center center;
    }
}

.PersonalCertificates .Browser .ActionPanel .Container .Shake .Checked,
.PersonalCertificates .Tablet .ActionPanel .Container .Shake .Checked,
.PersonalCertificates .Mobile .ActionPanel .Container .Shake .Checked {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    color: var(--090-black);
}

.PersonalCertificates .Browser .ActionPanel .Container .Shake .CheckedMax,
.PersonalCertificates .Tablet .ActionPanel .Container .Shake .CheckedMax,
.PersonalCertificates .Mobile .ActionPanel .Container .Shake .CheckedMax {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-font-size);
    line-height: var(--caption-line-height);
    color: var(--050-black);
}

.PersonalCertificates .Browser .ActionPanel .Container .ActionsMenu,
.PersonalCertificates .Tablet .ActionPanel .Container .ActionsMenu,
.PersonalCertificates .Mobile .ActionPanel .Container .ActionsMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
}

.PersonalCertificates .Browser .ActionPanel .Container .ActionsMenu .Action,
.PersonalCertificates .Tablet .ActionPanel .Container .ActionsMenu .Action,
.PersonalCertificates .Mobile .ActionPanel .Container .ActionsMenu .Action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 16px 10px 12px;
    white-space: nowrap;
    background-color: var(--004-black);
    border-radius: 40px;
    user-select: none;
    cursor: pointer;
}

.PersonalCertificates .Browser .ActionPanel .Container .ActionsMenu .Action:not(:last-child),
.PersonalCertificates .Tablet .ActionPanel .Container .ActionsMenu .Action:not(:last-child),
.PersonalCertificates .Mobile .ActionPanel .Container .ActionsMenu .Action:not(:last-child) {
    margin: 0 10px 0 0;
}

.PersonalCertificates .Browser .ActionPanel .Container .ActionsMenu .Action .Icon,
.PersonalCertificates .Tablet .ActionPanel .Container .ActionsMenu .Action .Icon,
.PersonalCertificates .Mobile .ActionPanel .Container .ActionsMenu .Action .Icon {
    display: flex;
    min-height: 24px;
    min-width: 24px;
    margin-right: 10px;
}

.PersonalCertificates .Browser .ActionPanel .Container .Blur,
.PersonalCertificates .Tablet .ActionPanel .Container .Blur,
.PersonalCertificates .Mobile .ActionPanel .Container .Blur {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(7px);
    z-index: 300;
}

.PersonalCertificates .Browser .ActionPanel .Container .Blur .Frame,
.PersonalCertificates .Tablet .ActionPanel .Container .Blur .Frame,
.PersonalCertificates .Mobile .ActionPanel .Container .Blur .Frame {
    height: 90vh;
    width: 90vw;
    max-width: 1000px;
    background-color: var(--white);
    box-shadow: 0 8px 24px var(--012-black), 0 0 4px var(--012-black);
    border: none;
    border-radius: 16px;
    z-index: 400;
}

.PersonalCertificates .Browser .Authorization,
.PersonalCertificates .Tablet .Authorization,
.PersonalCertificates .Mobile .Authorization {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.PersonalCertificates .Browser .Authorization .Icon,
.PersonalCertificates .Tablet .Authorization .Icon,
.PersonalCertificates .Mobile .Authorization .Icon {
    min-height: 34px;
    min-width: 34px;
    border-radius: 9px;
}

.PersonalCertificates .Browser .Authorization .Text,
.PersonalCertificates .Tablet .Authorization .Text {
    max-width: 420px;
    margin: 30px 0;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--subheader2-font-size);
    line-height: var(--subheader2-line-height);
    color: var(--black);
}

.PersonalCertificates .Mobile .Authorization .Text {
    max-width: 350px;
    margin: 30px 0;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--black);
}

.PersonalCertificates .Browser .Authorization .Button,
.PersonalCertificates .Tablet .Authorization .Button,
.PersonalCertificates .Mobile .Authorization .Button {
    padding: 10px 15px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body1-bold-font-weight);
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
    color: var(--050-black);
    background: var(--white);
    border: 2px solid var(--090-black);
    border-radius: 8px;
    transition: 0.5s;
    outline: none;
    cursor: pointer;
}

.PersonalCertificates .Browser .Authorization .Button:hover,
.PersonalCertificates .Tablet .Authorization .Button:hover,
.PersonalCertificates .Mobile .Authorization .Button:hover {
    color: var(--090-black);
}

.PersonalCertificates .Browser .Processing,
.PersonalCertificates .Tablet .Processing,
.PersonalCertificates .Mobile .Processing {
}

.PersonalCertificates .Browser .Processing .Blur,
.PersonalCertificates .Tablet .Processing .Blur,
.PersonalCertificates .Mobile .Processing .Blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(7px);
    z-index: 1000;
}

.PersonalCertificates .Browser .Processing .Blur .Animation,
.PersonalCertificates .Tablet .Processing .Blur .Animation,
.PersonalCertificates .Mobile .Processing .Blur .Animation {
    min-height: 200px;
    min-width: 200px;
}

@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    10% {
        transform: translate(1.9664300981627578px, 1.1485106701459067px) rotate(-0.312808073345457deg);
    }
    20% {
        transform: translate(-0.9334525100145585px, 1.0743489919170468px) rotate(1.3661649501334425deg);
    }
    30% {
        transform: translate(-1.2032055809632136px, 0.8727384227540638px) rotate(-1.0274227467364851deg);
    }
    40% {
        transform: translate(0.6361825122293219px, -0.0008380617845782368px) rotate(-0.7380664520134306deg);
    }
    50% {
        transform: translate(-1.9209610593295434px, -0.7182490648773721px) rotate(0.8911730795054922deg);
    }
    60% {
        transform: translate(1.4440824810079542px, 0.6822685316249322px) rotate(-0.9084844531425011deg);
    }
    70% {
        transform: translate(2.459522144583892px, -1.0209342739958571px) rotate(1.0069228611890857deg);
    }
    80% {
        transform: translate(0.13877099682136684px, -0.604216931622269px) rotate(-0.4202718518246751deg);
    }
    90% {
        transform: translate(2.4079118819966556px, 1.3386627377049254px) rotate(-1.1058704428553776deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}
