.Error {
}

.Error .Browser,
.Error .Tablet,
.Error .Mobile {
}

.Error .Browser .Blur,
.Error .Tablet .Blur,
.Error .Mobile .Blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(7px);
    z-index: 1000;
}

.Error .Browser .Frame,
.Error .Tablet .Frame,
.Error .Mobile .Frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Error .Browser .Frame .Container,
.Error .Tablet .Frame .Container,
.Error .Mobile .Frame .Container {
    max-width: 90%;
    background-color: var(--white);
    box-shadow: 0 8px 24px var(--012-black), 0 0 4px var(--012-black);
    border-radius: 16px;
    z-index: 1100;
}

.Error .Browser .Frame .Container .Header,
.Error .Tablet .Frame .Container .Header,
.Error .Mobile .Frame .Container .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    padding: 25px;
    margin-left: auto;
    margin-right: auto;
}

.Error .Browser .Frame .Container .Header .Title,
.Error .Tablet .Frame .Container .Header .Title,
.Error .Mobile .Frame .Container .Header .Title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Error .Browser .Frame .Container .Header .Close,
.Error .Tablet .Frame .Container .Header .Close,
.Error .Mobile .Frame .Container .Header .Close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Error .Browser .Frame .Container .Header .Close .Icon,
.Error .Tablet .Frame .Container .Header .Close .Icon,
.Error .Mobile .Frame .Container .Header .Close .Icon {
    min-height: 24px;
    min-width: 24px;
    cursor: pointer;
}

.Error .Browser .Frame .Container .Row,
.Error .Tablet .Frame .Container .Row,
.Error .Mobile .Frame .Container .Row {
    margin-left: auto;
    margin-right: auto;
}

.Error .Browser .Frame .Container .Content,
.Error .Tablet .Frame .Container .Content,
.Error .Mobile .Frame .Container .Content {
    max-width: 1000px;
    padding: 25px 25px 10px;
    margin-left: auto;
    margin-right: auto;
}

.Error .Browser .Frame .Container .Content .Row,
.Error .Tablet .Frame .Container .Content .Row,
.Error .Mobile .Frame .Container .Content .Row {
    margin: 0 0 15px;
    word-break: break-word;
}

.Error .Browser .Frame .Container .Recommendation,
.Error .Tablet .Frame .Container .Recommendation,
.Error .Mobile .Frame .Container .Recommendation {
    max-width: 1000px;
    padding: 25px;
    margin-left: auto;
    margin-right: auto;
}
