.Contacts {
    flex: 1 0 auto;
}

.Contacts .Browser,
.Contacts .Tablet,
.Contacts .Mobile {
}

.Contacts .Browser .TopRow,
.Contacts .Tablet .TopRow,
.Contacts .Mobile .TopRow {
    padding: 0 12px;
}

.Contacts .Browser .Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 0 25px;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Browser .Wrapper .Title {
    width: 340px;
    padding: 0 0 0 5vw;
    margin-left: auto;
    margin-right: 10px;
}

.Contacts .Tablet .Title,
.Contacts .Mobile .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Browser .Wrapper .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    padding: 0 5vw 0 0;
    margin-left: 10px;
    margin-right: auto;
}

.Contacts .Tablet .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 25px;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Mobile .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 10px;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Browser .Wrapper .Table .Row,
.Contacts .Tablet .Table .Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0;
}

.Contacts .Mobile .Table .Row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
}

.Contacts .Browser .Wrapper .Table .Row .Element,
.Contacts .Tablet .Table .Row .Element {
    width: 45vw;
}

.Contacts .Browser .Wrapper .Table .Row .Element .Link,
.Contacts .Tablet .Table .Row .Element .Link,
.Contacts .Mobile .Table .Row .Link {
    text-decoration: inherit;
    color: var(--blue);
}

.Contacts .Browser .BottomRow,
.Contacts .Tablet .BottomRow,
.Contacts .Mobile .BottomRow {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Browser .Schedule {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 60px;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Tablet .Schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 60px;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Mobile .Schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 45px;
    margin-left: auto;
    margin-right: auto;
}

.Contacts .Browser .Schedule .Info {
    width: 340px;
    margin-left: auto;
    margin-right: 10px;
}

.Contacts .Browser .Schedule .Table {
    width: 640px;
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    margin: 45px auto 60px 10px;
}

.Contacts .Tablet .Schedule .Table {
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 45px 0 60px;
    padding: 0;
}

.Contacts .Mobile .Schedule .Table {
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 30px 0 45px;
    padding: 0;
}

.Contacts .Browser .Schedule .Table .Row,
.Contacts .Tablet .Schedule .Table .Row,
.Contacts .Mobile .Schedule .Table .Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.Contacts .Browser .Schedule .Table .Row .Element,
.Contacts .Tablet .Schedule .Table .Row .Element,
.Contacts .Mobile .Schedule .Table .Row .Element {
    width: 40vw;
    padding: 0 10px;
}
