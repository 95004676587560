.Services {
}

.Services .Browser,
.Services .Tablet,
.Services .Mobile {
}

.Services .Browser .Container,
.Services .Tablet .Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 750px;
    padding: 0 5vw 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Services .Mobile .Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1000px;
    padding: 0 5vw 15px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Services .Browser .Container .Service,
.Services .Tablet .Container .Service,
.Services .Mobile .Container .Service {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 288px;
    padding: 5px;
    margin: 0 0 15px 0;
    background-color: var(--004-black);
    border-radius: 14px;
    text-decoration: inherit;
    cursor: pointer;
}

.Services .Browser .Container .Service .Icon,
.Services .Tablet .Container .Service .Icon,
.Services .Mobile .Container .Service .Icon {
    min-height: 48px;
    min-width: 48px;
}

.Services .Browser .Container .Service .Text,
.Services .Tablet .Container .Service .Text,
.Services .Mobile .Container .Service .Text {
    width: 250px;
}

.Services .Browser .Container .Service .Info,
.Services .Tablet .Container .Service .Info,
.Services .Mobile .Container .Service .Info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Services .Browser .Container .Service .Info .Icon,
.Services .Tablet .Container .Service .Info .Icon,
.Services .Mobile .Container .Service .Info .Icon {
    min-height: 24px;
    min-width: 24px;
}

.Services .Browser .Display {
    max-width: 1000px;
    padding: 15px 5vw 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Services .Tablet .Display {
    max-width: 1000px;
    padding: 0 5vw 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Services .Mobile .Display {
    max-width: 1000px;
    padding: 0 5vw 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.Services .Browser .Display .Frame,
.Services .Tablet .Display .Frame,
.Services .Mobile .Display .Frame {
    height: 90vh;
    width: 100%;
    max-height: 1000px;
    max-width: 1000px;
    border: none;
}

.Services .Browser .Display .FrameNarrow {
    height: 635px;
    width: 100%;
    max-height: 1000px;
    max-width: 1000px;
    border: none;
}

.Services .Tablet .Display .FrameNarrow {
    height: 635px;
    width: 100%;
    max-height: 1000px;
    max-width: 1000px;
    border: none;
}

.Services .Mobile .Display .FrameNarrow {
    height: 765px;
    width: 100%;
    max-height: 1000px;
    max-width: 1000px;
    border: none;
}
