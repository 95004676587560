.CheckBox {
    display: inline-block;
}

.CheckBox .Browser,
.CheckBox .Tablet,
.CheckBox .Mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CheckBox .Browser .Input,
.CheckBox .Tablet .Input,
.CheckBox .Mobile .Input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.CheckBox .Browser .Input + label,
.CheckBox .Tablet .Input + label,
.CheckBox .Mobile .Input + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.CheckBox .Browser .Input + label::before,
.CheckBox .Tablet .Input + label::before,
.CheckBox .Mobile .Input + label::before {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    height: 24px;
    width: 24px;
    content: '';
    border: 1px solid var(--050-black);
    border-radius: 6px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
}

.CheckBox .Browser .Input:checked + label::before,
.CheckBox .Tablet .Input:checked + label::before,
.CheckBox .Mobile .Input:checked + label::before {
    border: 1px solid var(--accent);
    background-color: var(--accent);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
