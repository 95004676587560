.Authorization {
}

.Authorization .Browser,
.Authorization .Tablet,
.Authorization .Mobile {
}

.Authorization .Browser .Blur,
.Authorization .Tablet .Blur,
.Authorization .Mobile .Blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(7px);
    z-index: 300;
}

.Authorization .Browser .Frame,
.Authorization .Tablet .Frame,
.Authorization .Mobile .Frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Authorization .Browser .Frame .Container,
.Authorization .Tablet .Frame .Container,
.Authorization .Mobile .Frame .Container {
    min-width: 320px;
    max-width: 90%;
    background-color: var(--white);
    box-shadow: 0 8px 24px var(--012-black), 0 0 4px var(--012-black);
    border-radius: 16px;
    z-index: 400;
}
