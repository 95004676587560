.RevokedCertificate {
}

.RevokedCertificate .Browser,
.RevokedCertificate .Tablet,
.RevokedCertificate .Mobile {
    width: 485px;
    max-width: 90vw;
}

.RevokedCertificate .Browser .Card,
.RevokedCertificate .Tablet .Card,
.RevokedCertificate .Mobile .Card {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0;
    margin: 30px 0 0;
    border: 2px solid var(--012-black);
    border-radius: 8px;
}

.RevokedCertificate .Browser .Card .Row,
.RevokedCertificate .Tablet .Card .Row,
.RevokedCertificate .Mobile .Card .Row {
    display: flex;
    flex-direction: row;
    padding: 0 0 15px;
}

.RevokedCertificate .Browser .Card .Row .Left,
.RevokedCertificate .Tablet .Card .Row .Left {
    display: inline-block;
    align-self: center;
    width: 130px;
    min-width: 130px;
    margin: 0 15px 0 0;
}

.RevokedCertificate .Mobile .Card .Row .Left {
    display: inline-block;
    align-self: center;
    width: 80px;
    min-width: 80px;
    margin: 0 15px 0 0;
}

.RevokedCertificate .Browser .Card .Row .Left .Download,
.RevokedCertificate .Tablet .Card .Row .Left .Download,
.RevokedCertificate .Mobile .Card .Row .Left .Download {
    text-decoration: inherit;
    color: inherit;
}

.RevokedCertificate .Browser .Card .Row .Left .Download .Icon,
.RevokedCertificate .Tablet .Card .Row .Left .Download .Icon,
.RevokedCertificate .Mobile .Card .Row .Left .Download .Icon {
    min-height: 24px;
    min-width: 24px;
}

.RevokedCertificate .Browser .Card .Row .Left .Download .Icon path,
.RevokedCertificate .Tablet .Card .Row .Left .Download .Icon path,
.RevokedCertificate .Mobile .Card .Row .Left .Download .Icon path {
    fill: var(--070-black);
}

.RevokedCertificate .Browser .Card .Row .Right,
.RevokedCertificate .Tablet .Card .Row .Right,
.RevokedCertificate .Mobile .Card .Row .Right {
    display: inline-block;
    align-self: center;
    word-break: break-word;
}
