.Browser .Loading,
.Tablet .Loading,
.Mobile .Loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.Browser .Empty,
.Tablet .Empty,
.Mobile .Empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.Browser .Authorization,
.Tablet .Authorization,
.Mobile .Authorization {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
}

.Browser .Authorization .Icon,
.Tablet .Authorization .Icon,
.Mobile .Authorization .Icon {
    min-height: 34px;
    min-width: 34px;
    border-radius: 9px;
}

.Browser .Authorization .Text,
.Tablet .Authorization .Text {
    max-width: 420px;
    margin: 30px 0;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--subheader2-font-size);
    line-height: var(--subheader2-line-height);
    color: var(--black);
}

.Mobile .Authorization .Text {
    max-width: 350px;
    margin: 30px 0;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    color: var(--black);
}

.Browser .Authorization .Button,
.Tablet .Authorization .Button,
.Mobile .Authorization .Button {
    padding: 10px 15px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body1-bold-font-weight);
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
    color: var(--050-black);
    background: var(--white);
    border: 2px solid var(--090-black);
    border-radius: 8px;
    transition: 0.5s;
    outline: none;
    cursor: pointer;
}

.Browser .Authorization .Button:hover,
.Tablet .Authorization .Button:hover,
.Mobile .Authorization .Button:hover {
    color: var(--090-black);
}

.Browser .Content,
.Mobile .Content,
.Tablet .Content {
    width: 100%;
}

.Browser .Content .NotFound,
.Tablet .Content .NotFound,
.Mobile .Content .NotFound {
    margin-top: 30px;
    text-align: center;
}

.Browser .Content .SmartIDLogs,
.Tablet .Content .SmartIDLogs {
    width: 100%;
    margin: 30px 0 0;
    border: 2px solid var(--012-black);
    box-sizing: border-box;
    border-radius: 8px;
    border-spacing: 0;
}

.Mobile .Content .SmartIDLogs {
    width: 485px;
    max-width: 90vw;
}

.Browser .NextLogs,
.Tablet .NextLogs,
.Mobile .NextLogs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Browser .NextLogs .NextLogsButton,
.Tablet .NextLogs .NextLogsButton{
    display: flex;
    gap: 10px;
    margin-top: 30px;
    padding: 11px 20px 11px 20px;
    border: 2px solid var(--012-black);
    border-radius: 8px;
    color: var(--070-black);
    background: var(--white);
    text-align: center;
    align-items: center;
    font-family: Inter, sans-serif;
    font-weight: 600;
    cursor: pointer;
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
}

.Mobile .NextLogs .NextLogsButton{
    display: flex;
    gap: 10px;
    margin-top: 20px;
    padding: 11px 20px 11px 20px;
    border: 2px solid var(--012-black);
    border-radius: 8px;
    color: var(--070-black);
    background: var(--white);
    text-align: center;
    align-items: center;
    font-family: Inter, sans-serif;
    font-weight: 600;
    cursor: pointer;
    font-size: var(--body1-bold-font-size);
    line-height: var(--body1-bold-line-height);
}

.Mobile .NextLogs .NextLogsButton .NextLogsButtonText{
    display: flex;
    flex-direction: column;
}

.Browser .NextLogs .NextLogsButton.Disabled,
.Tablet .NextLogs .NextLogsButton.Disabled,
.Mobile .NextLogs .NextLogsButton.Disabled{
    background-color: var(--004-black);
    cursor: not-allowed;
}

.Spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(117, 175, 38, 1);
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
