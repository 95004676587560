.Card {
    display: flex;
    flex-direction: column;
    padding: 20px 15px 0 15px;
    margin: 15px 0 0;
    border: 2px solid var(--012-black);
    border-radius: 8px;
}

.Card .Row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.Card .Row .Left {
    display: inline-block;
    align-self: center;
    width: 80px;
    min-width: 80px;
    margin: 0 15px 0 0;
}

.Card .Row .Right {
    display: inline-block;
    align-self: center;
    word-break: break-word;
}

.Card .ShowAllDocs {
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    color: rgba(117, 175, 38, 1);
    padding-bottom: 20px;
}

.Card .DocumentsList {
    padding: 0;
    margin: 0;
    overflow: hidden;
    transition: height 0.3s ease; /* Анимация высоты */
}
.Card .DocumentsList ul {
    margin: 0;
    padding: 0 0 0 20px;
}

.Card .DocumentsList ul .DocumentItem {
    line-height: 18px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--090-black);
}