.Browser .DateRangePicker,
.Tablet .DateRangePicker,
.Mobile .DateRangePicker {
    position: relative;
}

.Browser .DateRangePicker .UnselectedButton,
.Tablet .DateRangePicker .UnselectedButton,
.Mobile .DateRangePicker .UnselectedButton {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--012-black);
    color: var(--050-black);
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    font-family: Inter, sans-serif;
    font-weight: 400;
    border-radius: 8px;
    padding: 10px 15px;
    height: 44px;
    outline: none;
    justify-content: space-between;
}

.Browser .DateRangePicker .ButtonSelected,
.Tablet .DateRangePicker .ButtonSelected,
.Mobile .DateRangePicker .ButtonSelected {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--012-black);
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: var(--090-black);
    border-radius: 8px;
    padding: 10px 15px;
    height: 44px;
    outline: none;
    justify-content: space-between;
}

.Browser .DateRangePicker .DateRangeButton,
.Tablet .DateRangePicker .DateRangeButton,
.Mobile .DateRangePicker .DateRangeButton {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
    flex-direction: row;
}

.Browser .DateRangePicker .DateRangeButton .DateRangeButtonText {

}

.Tablet .DateRangePicker .DateRangeButton .DateRangeButtonText,
.Mobile .DateRangePicker .DateRangeButton .DateRangeButtonText {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: none;
}

.Browser .DateRangePicker .DateRangeLabelText,
.CalendarWrapperMob .MonthControl .DateRangeLabelText,
.CalendarWrapperMob .MonthControl .DateRangeLabelText {
    line-height: var(--site-title-line-height);
    font-family: PryvatSans, sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: var(--070-black);
    text-align: center;
    letter-spacing: 0.4px;
}

.Browser .DateRangePicker .DateRangeLabelText::first-letter,
.CalendarWrapperMob .MonthControl .DateRangeLabelText::first-letter,
.CalendarWrapperMob .MonthControl .DateRangeLabelText::first-letter {
    text-transform: uppercase;
}

.DateRangePicker .CalendarWrapper {
    position: absolute;
    top: 51px;
    width: 320px;
    height: 300px;
    z-index: 10;
    background-color: var(--white);
    border: none;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 24px 32px 0 rgba(0, 0, 0, 0.03),
    0 16px 32px 0 rgba(0, 0, 0, 0.02),
    0 12px 16px 0 rgba(0, 0, 0, 0.02),
    0 4px 8px 0 rgba(0, 0, 0, 0.02),
    0 2px 8px 0 rgba(0, 0, 0, 0.12),
    0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.CalendarWrapperMob {
    width: 320px;
    height: 300px;
    background-color: var(--white);
    border: none;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 24px 32px 0 rgba(0, 0, 0, 0.03),
    0 16px 32px 0 rgba(0, 0, 0, 0.02),
    0 12px 16px 0 rgba(0, 0, 0, 0.02),
    0 4px 8px 0 rgba(0, 0, 0, 0.02),
    0 2px 8px 0 rgba(0, 0, 0, 0.12),
    0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.DateRangePicker .CalendarWrapper .MonthControl,
.CalendarWrapperMob .MonthControl {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DateRangePicker .CalendarWrapper .ArrowButton,
.CalendarWrapperMob .ArrowButton {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.DateRangePicker .CalendarWrapper .Weekdays,
.CalendarWrapperMob .Weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-family: Geometria, serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.6px;
    text-align: center;
    color: var(--050-black);
    padding: 20px 0 12px 0;
}

.DateRangePicker .CalendarWrapper .DaysGrid,
.CalendarWrapperMob .DaysGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-row-gap: 3px;
    text-align: center;
    align-items: center;
    justify-items: center;
}

.DateRangePicker .CalendarWrapper .Day,
.CalendarWrapperMob .Day {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 36px;
    font-size: 15px;
    font-family: PryvatSans, sans-serif;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.DateRangePicker .CalendarWrapper .Day:nth-child(7n),
.CalendarWrapperMob .Day:nth-child(7n) {
    border-radius: 0 4px 4px 0;
}

.DateRangePicker .CalendarWrapper .Day:nth-child(7n+1),
.CalendarWrapperMob .Day:nth-child(7n+1){
    border-radius: 4px 0 0 4px;
}

/* Фон для выделенного диапазона, если оба конца существуют */
.DateRangePicker .CalendarWrapper .FullRangeStartPoint,
.CalendarWrapperMob .FullRangeStartPoint {
    position: relative;
    border-radius: unset;
    background: linear-gradient(to right, var(--white) 50%, rgba(78, 165, 36, 0.3) 50%);
}

.DateRangePicker .CalendarWrapper .FullRangeEndPoint,
.CalendarWrapperMob .FullRangeEndPoint{
    position: relative;
    border-radius: unset;
    background: linear-gradient(to right, rgba(78, 165, 36, 0.3) 50%, var(--white) 50%);
}

.DateRangePicker .CalendarWrapper .Range,
.CalendarWrapperMob .Range {
    background-color: rgba(78, 165, 36, 0.3); /* Полупрозрачный фон */
}

.DateRangePicker .CalendarWrapper .RangeStart::before,
.DateRangePicker .CalendarWrapper .RangeEnd::before,
.CalendarWrapperMob .RangeStart::before,
.CalendarWrapperMob .RangeEnd::before {
    content: '';
    position: absolute;
    width: 36px; /* Диаметр круга */
    height: 36px;
    background-color: rgba(78, 165, 36, 1); /* Сплошной круглый фон */
    border-radius: 50%; /* Делаем круг */
    z-index: 1;
}

.DateRangePicker .CalendarWrapper .RangeStart span,
.DateRangePicker .CalendarWrapper .RangeEnd span,
.CalendarWrapperMob .RangeStart span,
.CalendarWrapperMob .RangeEnd span {
    position: relative;
    z-index: 2;
    color: var(--white);
    font-weight: 500;
}

.DateRangePicker .CalendarWrapper .Today,
.CalendarWrapperMob .Today{
    color: rgba(238, 41, 52, 1);
    font-weight: 500;
}

.DateRangePicker .CalendarWrapper .EmptyDay,
.CalendarWrapperMob .EmptyDay {
    visibility: hidden;
}