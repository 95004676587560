.Menu {
}

.Menu .Browser,
.Menu .Tablet,
.Menu .Mobile {
}

.Menu .Browser .Icon,
.Menu .Tablet .Icon,
.Menu .Mobile .Icon {
    min-height: 34px;
    min-width: 34px;
    border-radius: 9px;
    cursor: pointer;
}

.Menu .Browser .Blur,
.Menu .Tablet .Blur,
.Menu .Mobile .Blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    backdrop-filter: blur(7px);
    z-index: 100;
}

.Menu .Browser .Arrow {
    min-height: 29px;
    min-width: 29px;
    position: absolute;
    top: 50px;
    right: 14px;
    z-index: 201;
}

.Menu .Browser .Frame {
    min-width: 300px;
    max-width: 400px;
    position: absolute;
    top: 63px;
    right: 5px;
    background-color: var(--white);
    box-shadow: 0 8px 24px var(--012-black), 0 0 4px var(--012-black);
    border-radius: 12px;
    z-index: 200;
}

.Menu .Tablet .Frame,
.Menu .Mobile .Frame {
    min-width: 300px;
    max-width: 400px;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: var(--white);
    box-shadow: 0 8px 24px var(--012-black), 0 0 4px var(--012-black);
    border-radius: 16px;
    z-index: 200;
}

.Menu .Browser .Frame .Control,
.Menu .Tablet .Frame .Control,
.Menu .Mobile .Frame .Control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
}

.Menu .Browser .Frame .Control .Languages,
.Menu .Tablet .Frame .Control .Languages,
.Menu .Mobile .Frame .Control .Languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Menu .Browser .Frame .Control .Languages .Container,
.Menu .Tablet .Frame .Control .Languages .Container,
.Menu .Mobile .Frame .Control .Languages .Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Menu .Browser .Frame .Control .Languages .Container .ActiveLanguage,
.Menu .Tablet .Frame .Control .Languages .Container .ActiveLanguage,
.Menu .Mobile .Frame .Control .Languages .Container .ActiveLanguage {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body2-bold-font-weight);
    font-size: var(--body2-bold-font-size);
    line-height: var(--body2-bold-line-height);
    letter-spacing: var(--body2-bold-letter-spacing);
    color: var(--090-black);
    cursor: default;
}

.Menu .Browser .Frame .Control .Languages .Container .InactiveLanguage,
.Menu .Tablet .Frame .Control .Languages .Container .InactiveLanguage,
.Menu .Mobile .Frame .Control .Languages .Container .InactiveLanguage {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body2-bold-font-weight);
    font-size: var(--body2-bold-font-size);
    line-height: var(--body2-bold-line-height);
    letter-spacing: var(--body2-bold-letter-spacing);
    color: var(--050-black);
    cursor: pointer;
}

.Menu .Browser .Frame .Control .Languages .Line,
.Menu .Tablet .Frame .Control .Languages .Line,
.Menu .Mobile .Frame .Control .Languages .Line {
    margin: 0 15px;
    color: var(--050-black);
}

.Menu .Browser .Frame .Control .Close,
.Menu .Tablet .Frame .Control .Close,
.Menu .Mobile .Frame .Control .Close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Menu .Browser .Frame .Control .Close .Icon,
.Menu .Tablet .Frame .Control .Close .Icon,
.Menu .Mobile .Frame .Control .Close .Icon {
    min-height: 24px;
    min-width: 24px;
    cursor: pointer;
}

.Menu .Browser .Frame .Row,
.Menu .Tablet .Frame .Row,
.Menu .Mobile .Frame .Row {
    margin-left: auto;
    margin-right: auto;
}

.Menu .Browser .Frame .Links,
.Menu .Tablet .Frame .Links,
.Menu .Mobile .Frame .Links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 25px 0;
}

.Menu .Browser .Frame .Links .Link,
.Menu .Tablet .Frame .Links .Link,
.Menu .Mobile .Frame .Links .Link {
    padding: 0 0 25px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    text-decoration: inherit;
    color: var(--090-black);
}

.Menu .Browser .Frame .Authorization {
    display: flex;
    flex-direction: row;
}

.Menu .Tablet .Frame .Authorization,
.Menu .Mobile .Frame .Authorization {
    display: flex;
    flex-direction: row;
    padding: 25px 25px 0;
}

.Menu .Browser .Frame .Authorization .Icon,
.Menu .Tablet .Frame .Authorization .Icon,
.Menu .Mobile .Frame .Authorization .Icon {
    min-height: 34px;
    min-width: 34px;
    margin: 0 0 25px;
    border-radius: 9px;
}

.Menu .Browser .Frame .Authorization .AuthorizedActions {
    width: 100%;
}

.Menu .Tablet .Frame .Authorization .AuthorizedActions,
.Menu .Mobile .Frame .Authorization .AuthorizedActions {
    display: flex;
    flex-direction: column;
}

.Menu .Browser .Frame .Authorization .UnauthorizedActions,
.Menu .Tablet .Frame .Authorization .UnauthorizedActions,
.Menu .Mobile .Frame .Authorization .UnauthorizedActions {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Menu .Browser .Frame .Authorization .AuthorizedActions .Action,
.Menu .Browser .Frame .Authorization .UnauthorizedActions .Action {
    padding: 0 0 15px;
}

.Menu .Tablet .Frame .Authorization .AuthorizedActions .Action,
.Menu .Mobile .Frame .Authorization .AuthorizedActions .Action,
.Menu .Tablet .Frame .Authorization .UnauthorizedActions .Action,
.Menu .Mobile .Frame .Authorization .UnauthorizedActions .Action {
    padding: 0 0 25px 25px;
}

.Menu .Browser .Frame .Authorization .AuthorizedActions .Row,
.Menu .Tablet .Frame .Authorization .AuthorizedActions .Row,
.Menu .Mobile .Frame .Authorization .AuthorizedActions .Row {
    padding: 0 0 15px;
    margin-left: auto;
    margin-right: auto;
}

.Menu .Browser .Frame .Authorization .AuthorizedActions .Action .Link {
    display: inline-block;
    margin: 0 15px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    text-decoration: inherit;
    color: var(--090-black);
}

.Menu .Tablet .Frame .Authorization .AuthorizedActions .Action .Link,
.Menu .Mobile .Frame .Authorization .AuthorizedActions .Action .Link {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    text-decoration: inherit;
    color: var(--090-black);
}

.Menu .Browser .Frame .Authorization .AuthorizedActions .Action .Logout {
    display: inline-block;
    margin: 0 15px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    text-decoration: inherit;
    color: var(--090-black);
    cursor: pointer;
}

.Menu .Tablet .Frame .Authorization .AuthorizedActions .Action .Logout,
.Menu .Mobile .Frame .Authorization .AuthorizedActions .Action .Logout {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    text-decoration: inherit;
    color: var(--090-black);
    cursor: pointer;
}

.Menu .Browser .Frame .Authorization .UnauthorizedActions .Action .Login {
    margin: 15px 15px 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body2-font-size);
    line-height: var(--body2-line-height);
    text-decoration: inherit;
    color: var(--090-black);
    cursor: pointer;
}

.Menu .Tablet .Frame .Authorization .UnauthorizedActions .Action .Login,
.Menu .Mobile .Frame .Authorization .UnauthorizedActions .Action .Login {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--body1-font-size);
    line-height: var(--body1-line-height);
    text-decoration: inherit;
    color: var(--090-black);
    cursor: pointer;
}
