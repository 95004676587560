.Header {
    width: 100%;
}

.Header .Browser,
.Header .Tablet,
.Header .Mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 12px;
}

.Header .Browser .Top,
.Header .Tablet .Top,
.Header .Mobile .Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Header .Browser .Top .TitleLink,
.Header .Tablet .Top .TitleLink,
.Header .Mobile .Top .TitleLink {
    text-decoration: none;
}

.Header .Browser .Top .TitleLink .Title,
.Header .Tablet .Top .TitleLink .Title,
.Header .Mobile .Top .TitleLink .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Header .Browser .Top .TitleLink .Title .AcskLogo,
.Header .Tablet .Top .TitleLink .Title .AcskLogo,
.Header .Mobile .Top .TitleLink .Title .AcskLogo {
    width: 34px;
    height: 34px;
}

.Header .Browser .Top .TitleLink .Title .Text,
.Header .Tablet .Top .TitleLink .Title .Text,
.Header .Mobile .Top .TitleLink .Title .Text {
    padding: 0 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--site-title-font-weight);
    font-size: var(--site-title-font-size);
    line-height: var(--site-title-line-height);
    color: var(--090-black);
}

.Header .Browser .Links,
.Header .Tablet .Links,
.Header .Mobile .Links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70vw;
}

.Header .Browser .Links .Link,
.Header .Tablet .Links .Link,
.Header .Mobile .Links .Link {
    padding: 0 5px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: var(--body2-bold-font-weight);
    font-size: var(--body2-bold-font-size);
    line-height: var(--body2-bold-line-height);
    letter-spacing: var(--body2-bold-letter-spacing);
    text-decoration: inherit;
    color: var(--090-black);
}

.Header .Browser .Control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 7vw;
}
