.OnlineServices {
    flex: 1 0 auto;
}

.OnlineServices .Browser,
.OnlineServices .Tablet,
.OnlineServices .Mobile {
}

.OnlineServices .Browser .TopRow,
.OnlineServices .Tablet .TopRow,
.OnlineServices .Mobile .TopRow {
    padding: 0 12px;
}

.OnlineServices .Browser .Title,
.OnlineServices .Tablet .Title,
.OnlineServices .Mobile .Title {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.OnlineServices .Browser .BottomRow,
.OnlineServices .Tablet .BottomRow,
.OnlineServices .Mobile .BottomRow {
    max-width: 1000px;
    padding: 0 5vw;
    margin-left: auto;
    margin-right: auto;
}

.OnlineServices .Browser .Help,
.OnlineServices .Tablet .Help {
    max-width: 1000px;
    padding: 45px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.OnlineServices .Mobile .Help {
    max-width: 1000px;
    padding: 30px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.OnlineServices .Browser .Table,
.OnlineServices .Tablet .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 75px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.OnlineServices .Mobile .Table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 0 5vw 60px 5vw;
    margin-left: auto;
    margin-right: auto;
}

.OnlineServices .Browser .Table .Row,
.OnlineServices .Tablet .Table .Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0;
}

.OnlineServices .Mobile .Table .Row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
}

.OnlineServices .Browser .Table .Row .Element,
.OnlineServices .Tablet .Table .Row .Element {
    width: 45vw;
}

.OnlineServices .Browser .Table .Row .Element .Link,
.OnlineServices .Tablet .Table .Row .Element .Link,
.OnlineServices .Mobile .Table .Row .Link {
    text-decoration: inherit;
    color: var(--blue);
}
